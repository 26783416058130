h1, h3 {
    text-align: center;
}
p {
    padding-left: 10px;
    padding-right: 10px;
}
pre {
    width: 90%;
    text-align: left;
    font-size: 0.8rem;
    background: hsl(0,0%,96.5%);
    border: #6C717B;
    overflow-x: auto;
    padding-top: 40px;
    padding-bottom: 20px;
}
section {
    width: 100%;
}
.showcode {
    color: cadetblue;
    cursor: pointer;
    text-decoration: underline;
}
.code-block {
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    overflow-x: auto;
    display: block;
    position: relative;
}

.copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #8c8c8c;
    border: white;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.9rem;
    margin: 4px 2px;
    cursor: pointer;
}
/***************/
ul.rules {
    width: 80%;
    max-width: 500px;
     }
     ul.rules     li {
        user-select: none;
        margin-bottom: 10px;
        display: flex;
        justify-content: left;
        flex-direction: row;
        align-items: flex-start;
     }
/*******/
ul.validation-checks {
    width: 95%;
    list-style: none;
     }
     ul.validation-checks     li {
        user-select: none;
        margin-bottom: 10px;
        display: flex;
        justify-content: left;
        flex-direction: row;
        align-items: flex-start;
     }
     ul.validation-checks li img {
        height: 1rem;
        vertical-align: middle;
        margin-right: 10px;
     }
form.add {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 10px 0 5px;
    border-bottom: 1px solid #8e979c;
    width: 70%;
    margin: auto;
}
.invalidEmail {
    color: red;
    font-size: 0.8rem;
    text-align: center;
    display: block;
}
.add input[type=text] {
    font-weight: 700;
    font-size: 1.1rem;
    padding-left: 5px;
    color: #6C717B;
    width: 100%;
}

form.add > * {
    background: transparent;
    border: none;
    height: 35px;
}
 ul.recipients {
width: 80%;
max-width: 500px;
 }
 ul.recipients li {
    user-select: none;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
 }
 ul.recipients li .email {
    text-align: left;
 }
 ul.recipients li .remove {
    color: #6C717B;
    cursor: pointer;
 }